import { ActionTree } from 'vuex';

import axios from 'qs_vuetify/src/plugins/axios';

import { District } from 'qs_vuetify/src/types/models';

import { DistrictsState, RootState } from '@/types/states';

export const actions: ActionTree<DistrictsState, RootState> = {
  async searchByPostalCode({ commit }, postalCode) {
    commit('loading', true);
    commit('error', null);

    try {
      const { data: { data } }: { data: { data: Array<District> } } = await axios.get(
        '/districts',
        {
          params: {
            postal_code: postalCode.toUpperCase().replace(/ /g, ''),
          },
        },
      );

      commit('data', data);
    } catch (err: any) {
      if (axios.isAxiosError(err) && err.response?.data) {
        commit('error', err.response.data);
      }

      throw err;
    } finally {
      commit('loading', false);
    }
  },
};

export default actions;
