import { Module } from 'vuex';

import { RootState, ViewsState } from '@/types/states';

export const initialState = {
  customBillingAddress: false,
  email: '',
  emailModalVisible: false,
  formValid: true,
  redirectCount: 0,
  stripeFormData: {
    address: '',
    city: '',
    home_phone: '',
    name: '',
    postal_code: '',
  },
};

const ViewsModule: Module<ViewsState, RootState> = {
  namespaced: true,
  state: initialState,
  getters: {
    customBillingAddress(state) {
      return state.customBillingAddress;
    },
    email(state) {
      return state.email;
    },
    formValid(state) {
      return state.formValid;
    },
    stripeFormDataChanged(state) {
      return !!(state.stripeFormData.address || state.stripeFormData.city
        || state.stripeFormData.home_phone || state.stripeFormData.name
        || state.stripeFormData.postal_code);
    },
    stripeFormData(state) {
      return state.stripeFormData;
    },
    emailModalVisible(state) {
      return state.emailModalVisible;
    },
  },
  actions: {},
  mutations: {
    customBillingAddress(state, customBillingAddress) {
      state.customBillingAddress = customBillingAddress;
    },
    email(state, email) {
      state.email = email;
    },
    formValid(state, formValid) {
      state.formValid = formValid;
    },
    redirectCount(state, redirectCount) {
      state.redirectCount = redirectCount;
    },
    stripeFormData(state, stripeFormData) {
      Object.assign(state.stripeFormData, stripeFormData);
    },
    emailModalVisible(state, emailModalVisible) {
      state.emailModalVisible = emailModalVisible;
    },
  },
};

export default ViewsModule;
