
import Vue from 'vue';
import Component from 'vue-class-component';

import QsLayoutNotifications from 'qs_vuetify/src/components/Layout/QsLayoutNotifications.vue';

@Component({
  components: {
    QsLayoutNotifications,
  },
})
export default class App extends Vue {
}
