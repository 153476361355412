import Cookies from 'js-cookie';
import Vue from 'vue';
import Vuex, { Module, StoreOptions } from 'vuex';
import VuexPersistence from 'vuex-persist';
import axios from 'axios';

import GlobalViewModule from 'qs_vuetify/src/store/views/global';

import { GlobalViewState } from 'qs_vuetify/src/types/states';

import { RootState } from '@/types/states';

import actions from './actions';
import getters from './getters';
import mutations from './mutations';

import AuthModule from './auth/index';
import DistrictsModule from './districts/index';
import ViewsModule from './views';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence<RootState>({
  key: 'qs_profil_v2',
  storage: window.localStorage,
  reducer(state) {
    return {
      views: state.views,
    };
  },
});

const vuexCookie = new VuexPersistence<RootState>({
  key: 'qs_auth_v2',
  restoreState: (key) => Cookies.getJSON(key),
  saveState: async (key, state) => {
    await Cookies.set(key, state, {
      expires: 3,
      secure: process.env.NODE_ENV !== 'development',
      domain: process.env.VUE_APP_COOKIE_DOMAIN,
      path: '/',
      sameSite: 'Lax',
    });
  },
  reducer(state) {
    return {
      auth: {
        token: state.auth?.token,
        refreshToken: state.auth?.refreshToken,
      },
    };
  },
});

const initialState = {
  error: null,
  loading: false,
  message: '',
  version: '0.1.0',
};

const storeOptions: StoreOptions<RootState> = {
  state: initialState,
  actions,
  getters,
  mutations: {
    ...mutations,
    reset(state) {
      Object.assign(state, initialState);
    },
  },
  plugins: [vuexLocal.plugin, vuexCookie.plugin],
  modules: {
    auth: AuthModule,
    districts: DistrictsModule,
    global: GlobalViewModule as Module<GlobalViewState, RootState>,
    views: ViewsModule,
  },
};

const store = new Vuex.Store(storeOptions);

axios.interceptors.request.use((config) => {
  if (store.state.auth && store.state.auth.token) {
    // eslint-disable-next-line
    config.headers.common.Authorization = `Bearer ${store.state.auth.token}`;
  }
  return config;
});


export default store;
