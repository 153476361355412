import { MutationTree } from 'vuex';

import { AuthState } from '@/types/states';

export const mutations: MutationTree<AuthState> = {
  contact(state, contact) {
    if (state.user) {
      state.user.contact = contact;
    }
  },
  error(state, error) {
    state.error = error;
  },
  initialItem(state, payload) {
    state.initialItem = payload;
  },
  loading(state, loading) {
    state.loading = loading;
  },
  refreshToken(state, refreshToken) {
    state.refreshToken = refreshToken;
  },
  redirect(state, redirect) {
    state.redirect = redirect;
  },
  scopes(state, scopes) {
    state.scopes = scopes;
  },
  token(state, token) {
    state.token = token;
  },
  user(state, user) {
    state.user = user;
  },
};

export default mutations;
