import { GetterTree } from 'vuex';

import { DistrictsState, RootState } from '@/types/states';

export const getters: GetterTree<DistrictsState, RootState> = {
  ajax(s) {
    return s.ajax;
  },
  data(s) {
    return s.data;
  },
  error(s) {
    return s.error;
  },
  loading(s) {
    return s.loading;
  },
};

export default getters;
