import { Module } from 'vuex';

import { AuthState, RootState } from '@/types/states';

import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';

export const state: AuthState = {
  error: null,
  initialItem: '{}',
  loading: false,
  redirect: null,
  refreshToken: null,
  scopes: [],
  token: null,
  user: null,
};

const AuthModule: Module<AuthState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default AuthModule;
