import { GetterTree } from 'vuex';

import { RootState } from '@/types/states';

export const getters: GetterTree<RootState, RootState> = {
  loading(state) {
    return state.loading;
  },
  message(state) {
    return state.message;
  },
};

export default getters;
