import { ActionTree } from 'vuex';

import axios from 'qs_vuetify/src/plugins/axios';

import { StripeCustomer } from 'qs_vuetify/src/types/models';
import { RootState } from '@/types/states';

export const actions: ActionTree<RootState, RootState> = {
  async updateStripeCustomer({ commit, state }, stripeCustomer: StripeCustomer) {
    commit('error', null);
    commit('loading', true);

    try {
      const { data } = await axios.put('/auth/user/contact/stripe_customer', stripeCustomer, {
        params: {
          fields: '*,payment_method.*',
        },
      });

      if (state?.auth?.user) {
        const {
          auth: {
            user,
          },
        } = state;

        if (user) {
          const newUser = {
            ...user,
            contact: {
              ...user.contact,
              stripe_customer: {
                ...(user?.contact?.stripe_customer || {}),
                ...data,
              },
            },
          };

          commit('auth/user', newUser);
        }
      }
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        commit('error', err.response.data);
      }

      throw err;
    } finally {
      commit('loading', false);
    }
  },
};

export default actions;
