import { Module } from 'vuex';

import { DistrictsState, RootState } from '@/types/states';

import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export const state: DistrictsState = {
  ajax: null,
  data: [],
  error: null,
  loading: false,
};

const DistrictsModule: Module<DistrictsState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default DistrictsModule;
