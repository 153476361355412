import { GetterTree } from 'vuex';

import { AuthState, RootState } from '@/types/states';

export const getters: GetterTree<AuthState, RootState> = {
  contact(state) {
    return state.user?.contact;
  },
  error(state) {
    return state.error;
  },
  initialItem(state) {
    return state.initialItem;
  },
  loading(state) {
    return state.loading;
  },
  refreshToken(state) {
    return state.refreshToken;
  },
  redirect(state) {
    return state.redirect;
  },
  scopes(state) {
    return state.scopes;
  },
  token(state) {
    return state.token;
  },
  user(state) {
    return state.user;
  },
};

export default getters;
