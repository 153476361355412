import { MutationTree } from 'vuex';

import { RootState } from '@/types/states';

export const mutations: MutationTree<RootState> = {
  error(state, error) {
    state.error = error;
  },
  loading(state, loading: boolean) {
    state.loading = loading;
  },
  message(state, message) {
    state.message = message;
  },
};

export default mutations;
