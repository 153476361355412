import Vue from 'vue';
import VueRouter, { Route, RouteConfig } from 'vue-router';

const Action = () => import('@/views/Action.vue');
const Login = () => import('@/views/Login.vue');
const Logout = () => import('@/views/Logout.vue');
const Profile = () => import('@/views/Profile.vue');
const RequestMemberId = () => import('@/views/RequestMemberId.vue');
const RequestPasswordReset = () => import('@/views/RequestPasswordReset.vue');
const ResetPassword = () => import('@/views/ResetPassword.vue');
const Stripe = () => import('@/views/Stripe.vue');

Vue.use(VueRouter);

export const routes: RouteConfig[] = [
  {
    path: '/',
    name: 'Login',
    component: Login,
  },
  {
    path: '/action/:action',
    name: 'Action',
    component: Action,
    props: (route) => ({
      variant: 'action',
      action: route.params.action,
    }),
  },
  {
    path: '/desinscription/:action',
    name: 'Action',
    component: Action,
    props: (route) => ({
      variant: 'desinscription',
      action: route.params.action,
    }),
  },
  {
    path: '/profil/zendesk',
    name: 'Zendesk',
    redirect(to: Route) {
      return {
        path: '/',
        query: {
          s: 'zendesk',
          r: to.query.return_to,
        },
      };
    },
  },
  {
    path: '/profil/deconnexion',
    name: 'LogoutZendesk',
    redirect(to: Route) {
      if (to.query?.kind === 'info') {
        return {
          path: '/deconnexion',
          query: {
            s: 'zendesk',
          },
        };
      }

      if (to.query?.kind === 'error') {
        return {
          path: '/profil',
          query: {
            s: 'zendesk',
            m: `Erreur de connexion à La Centrale: ${to.query.message || 'Inconnue'}`,
          },
        };
      }

      return {
        path: '/profil',
      };
    },
  },
  {
    path: '/profil',
    name: 'Profile',
    component: Profile,
  },
  {
    path: '/adhesion',
    name: 'Stripe',
    component: Stripe,
  },
  {
    path: '/demande_nouveau_mot_de_passe',
    name: 'RequestPasswordReset',
    component: RequestPasswordReset,
  },
  {
    path: '/nouveau_mot_de_passe/:token/:email',
    name: 'ResetPassword',
    component: ResetPassword,
  },
  {
    path: '/numero_de_membre_oublie',
    name: 'RequestMemberId',
    component: RequestMemberId,
  },
  {
    path: '/deconnexion',
    name: 'Logout',
    component: Logout,
  },
  {
    path: '/*',
    name: 'Redirect',
    redirect: '/',
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
