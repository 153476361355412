import { MutationTree } from 'vuex';

import { DistrictsState } from '@/types/states';

export const mutations: MutationTree<DistrictsState> = {
  ajax(state, ajax) {
    state.ajax = ajax;
  },
  data(state, data) {
    state.data = data;
  },
  error(state, error) {
    state.error = error;
  },
  loading(state, loading) {
    state.loading = loading;
  },
};

export default mutations;
